import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import MainButton from '../../UI/MainButton';
import isEmail from 'validator/lib/isEmail';
import SnackbarContext from '../../../store/snackbar-context';
import FormTextField from '../../UI/FormTextField';
import FormDropdown from '../../UI/FormDropdown';
import { getDistricts } from '../../../api/user-api';
import DividerWithButton from '../../UI/DividerWithButton';
import { loginPage } from '../../../store/fixed-routing';
import { registerNewTeacher } from '../../../api/auth-api';
import TextWithDivider from '../../UI/TextWithDivider';

const formErrors = {
	titleError: 'Proszę wybrać tytuł',
	degreeError: 'Proszę wpisać stopień naukowy',
	affiliationError: 'Proszę uzupełnić afiliację',
	categoryError: 'Proszę uzupełnić kategorię',
	studentsError: 'Proszę o wpisanie wszystkich podopiecznych',
	nameError: 'Proszę uzupełnić imię',
	secondNameError: 'Proszę uzupełnić drugie imię',
	surnameError: 'Proszę uzupełnić nazwisko',
	emailError: 'Wprowadź poprawny email',
	phoneError: '',
	districtError: 'Proszę wybrać okręg',
	streetError: 'Proszę wpisać ulicę',
	buildingError: 'Proszę wpisać numer budynku',
	apartmentError: '',
	zipCodeError: 'Proszę wpisać poprawny kod pocztowy',
	cityError: 'Proszę uzupełnić pole z miastem',
	rodoError: 'Proszę zaznaczyć zgody',
	consentError: 'Proszę zaznaczyć zgody',
};

const RegisterTeacher = () => {
	const { palette, typography } = useTheme();
	const [districts, setDistricts] = useState<any>();
	const [validated, setValidated] = useState(false);
	const { setMsg } = useContext(SnackbarContext);
	const [formInfo, setFormInfo] = useState({
		personalTitle: '',
		academicDegree: '',
		firstName: '',
		secondName: '',
		lastName: '',
		category: '',
		affiliation: '',
		students: '',
		email: '',
		phone: '',
		city: '',
		street: '',
		zipCode: '',
		building: '',
		apartment: '',
		districtId: '',
	});
	const [isSecondName, setIsSecondName] = useState<string>('');

	const [helperInputInfo, setHelperInputInfo] = useState({
		district: '',
		rodo: '',
		consent: '',
	});

	const [districtNames, setDistrictNames] = useState<any>([]);

	const [categoryNames, setCategoryNames] = useState<any>([
		'nauczyciel pracujący w szkole podstawowej / szkole ponadpodstawowej',
		'opiekun naukowy'
	]);

	useEffect(() => {
		const fetchData = async () => {
			const districts = await getDistricts();
			setDistricts(districts);

			districts &&
				districts.map((district: any, index: number) => {
					setDistrictNames((prev: any) => [...prev, district.name]);
				});
		};
		fetchData().catch((error) => console.log(error));
	}, []);

	const [formValidation, setFormValidation] = useState(formErrors);

	const titleHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				titleError: formErrors.titleError,
			});
		} else {
			setFormValidation({
				...formValidation,
				titleError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				personalTitle: value,
			};
		});
	};

	const degreeHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				degreeError: formErrors.degreeError,
			});
		} else {
			setFormValidation({
				...formValidation,
				degreeError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				academicDegree: value,
			};
		});
	};

	const affiliationHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				affiliationError: formErrors.affiliationError,
			});
		} else {
			setFormValidation({
				...formValidation,
				affiliationError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				affiliation: value,
			};
		});
	};

	const studentsHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				studentsError: formErrors.studentsError,
			});
		} else if(value.length >= 1000) {
			setFormValidation({
				...formValidation,
				studentsError: 'Przekroczono ilość znaków w polu uczniowie (maks. 1000 znaków)',
			});
		} else {
			setFormValidation({
				...formValidation,
				studentsError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				students: value,
			};
		});
	};

	const nameHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				nameError: formErrors.nameError,
			});
		} else {
			setFormValidation({
				...formValidation,
				nameError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				firstName: value,
			};
		});
	};

	const secondNameHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				secondNameError: formErrors.secondNameError,
			});
		} else {
			setFormValidation({
				...formValidation,
				secondNameError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				secondName: value,
			};
		});
	};

	const surnameHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				surnameError: formErrors.surnameError,
			});
		} else {
			setFormValidation({
				...formValidation,
				surnameError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				lastName: value,
			};
		});
	};

	const emailHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				emailError: formErrors.emailError,
			});
		} else if (!isEmail(value)) {
			setFormValidation({
				...formValidation,
				emailError: 'Niepoprawny adres e-mail',
			});
		} else {
			setFormValidation({
				...formValidation,
				emailError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				email: value,
			};
		});
	};

	const phoneHandler = (value: string) => {
		setFormInfo((prevState) => {
			return {
				...prevState,
				phone: value,
			};
		});
	};

	const categoryHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				categoryError: formErrors.categoryError,
			});
		} else {
			setFormValidation({
				...formValidation,
				categoryError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				category: value,
			};
		});
	};

	const districtHandler = (value: string) => {
		const district = districts.find((district: any) => district.name === value);

		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				districtError: formErrors.districtError,
			});
		} else {
			setFormValidation({
				...formValidation,
				districtError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				districtId: district.id,
			};
		});
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				district: value,
			};
		});
	};

	const streetHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				streetError: formErrors.streetError,
			});
		} else {
			setFormValidation({
				...formValidation,
				streetError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				street: value,
			};
		});
	};

	const buildingHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				buildingError: formErrors.buildingError,
			});
		} else {
			setFormValidation({
				...formValidation,
				buildingError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				building: value,
			};
		});
	};

	const apartmentHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				apartmentError: formErrors.apartmentError,
			});
		} else {
			setFormValidation({
				...formValidation,
				apartmentError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				apartment: value,
			};
		});
	};

	const zipCodeHandler = (value: string) => {
		const numericValue = value.replace(/\D/g, '');
		const formattedValue = numericValue.replace(/(\d{2})(\d{1,})/, '$1-$2');

		if (formattedValue.length === 6) {
			setFormValidation({
				...formValidation,
				zipCodeError: '',
			});
		} else {
			setFormValidation({
				...formValidation,
				zipCodeError: formErrors.zipCodeError,
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				zipCode: formattedValue,
			};
		});
	};

	const cityHandler = (value: string) => {
		if (value.length < 1) {
			setFormValidation({
				...formValidation,
				cityError: formErrors.cityError,
			});
		} else {
			setFormValidation({
				...formValidation,
				cityError: '',
			});
		}
		setFormInfo((prevState) => {
			return {
				...prevState,
				city: value,
			};
		});
	};

	const rodoHandler = (e: any) => {
		if (!e.target.checked) {
			setFormValidation({
				...formValidation,
				rodoError: formErrors.rodoError,
			});
		} else {
			setFormValidation({
				...formValidation,
				rodoError: '',
			});
		}
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				rodo: e.target.checked,
			};
		});
	};

	const consentHandler = (e: any) => {
		if (!e.target.checked) {
			setFormValidation({
				...formValidation,
				consentError: formErrors.consentError,
			});
		} else {
			setFormValidation({
				...formValidation,
				consentError: '',
			});
		}
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				consent: e.target.checked,
			};
		});
	};

	const resetInputs = () => {
		setFormInfo((prevState) => {
			return {
				...prevState,
				personalTitle: '',
				academicDegree: '',
				firstName: '',
				secondName: '',
				lastName: '',
				affiliation: '',
				category: '',
				students: '',
				email: '',
				phone: '',
				city: '',
				street: '',
				zipCode: '',
				building: '',
				apartment: '',
				districtId: '',
			};
		});
		setHelperInputInfo((prevState) => {
			return {
				...prevState,
				district: '',
				rodo: '',
				consent: ''
			};
		});
		setValidated(false);
	};

	const [loading, setLoading] = useState(false);

	const actionSend = () => {
		setValidated(true);
		if (formValidation.titleError !== '') {
			setMsg({ msg: formValidation.titleError, severity: 'warning' });
			return;
		}
		if (formValidation.nameError !== '') {
			setMsg({ msg: formValidation.nameError, severity: 'warning' });
			return;
		}
		if (isSecondName && formValidation.secondNameError !== '') {
			setMsg({ msg: formValidation.secondNameError, severity: 'warning' });
			return;
		}
		if (formValidation.surnameError !== '') {
			setMsg({ msg: formValidation.surnameError, severity: 'warning' });
			return;
		}
		if (formValidation.streetError !== '') {
			setMsg({ msg: formValidation.streetError, severity: 'warning' });
			return;
		}
		if (formValidation.buildingError !== '') {
			setMsg({ msg: formValidation.buildingError, severity: 'warning' });
			return;
		}
		if (formValidation.apartmentError !== '') {
			setMsg({ msg: formValidation.apartmentError, severity: 'warning' });
			return;
		}
		if (formValidation.zipCodeError !== '') {
			setMsg({ msg: formValidation.zipCodeError, severity: 'warning' });
			return;
		}
		if (formValidation.cityError !== '') {
			setMsg({ msg: formValidation.cityError, severity: 'warning' });
			return;
		}
		if (formValidation.degreeError !== '') {
			setMsg({ msg: formValidation.degreeError, severity: 'warning' });
			return;
		}
		if (formValidation.categoryError !== '') {
			setMsg({ msg: formValidation.categoryError, severity: 'warning' });
			return;
		}
		if (formValidation.affiliationError !== '') {
			setMsg({ msg: formValidation.affiliationError, severity: 'warning' });
			return;
		}
		if (formValidation.studentsError !== '') {
			setMsg({ msg: formValidation.studentsError, severity: 'warning' });
			return;
		}
		if (formValidation.emailError !== '') {
			setMsg({ msg: formValidation.emailError, severity: 'warning' });
			return;
		}
		if (formValidation.phoneError !== '') {
			setMsg({ msg: formValidation.phoneError, severity: 'warning' });
			return;
		}
		if (formValidation.districtError !== '') {
			setMsg({ msg: formValidation.districtError, severity: 'warning' });
			return;
		}
		if (formValidation.rodoError !== '') {
			setMsg({ msg: formValidation.rodoError, severity: 'warning' });
			return;
		}
		if (formValidation.consentError !== '') {
			setMsg({ msg: formValidation.consentError, severity: 'warning' });
			return;
		}
		if (formInfo) {
			setLoading(true);
			registerNewTeacher(formInfo, (error: string) => console.log(error))
				.then((res: any) => {
					setLoading(false);
					if (typeof res === 'string' && res === '') {
						setMsg({
							msg: 'Pomyślnie zarejestrowano',
							severity: 'success',
						});
						resetInputs();
					} else if (typeof res === 'string') {
						setMsg({
							msg: res,
							severity: 'error',
						});
					}
				})
				.catch((error: any) => {
					setLoading(false);
					setMsg({
						msg: 'Nastąpił błąd przy rejestracji! Spróbuj ponownie później.',
						severity: 'error',
					});
				});
		}
	};

	return (
		<Grid
			width={'100%'}
			maxWidth={'650px'}
			display={'flex'}
			flexDirection={'column'}
			gap={'24px'}
		>
			<Typography
				variant={'h3'}
				fontWeight={'500'}
				color={palette.primary.main}
			>
				Zarejestruj się - nauczyciel
			</Typography>
			<Grid
				component={'form'}
				display={'flex'}
				flexDirection={'column'}
			>
				<TextWithDivider
					text={'Informacje o Tobie'}
					sx={{
						mt: '16px',
						mb: '24px',
					}}
				/>
				<Box
					display={'flex'}
					flexDirection={'column'}
					alignItems={'flex-start'}
					gap={'16px'}
					width={'100%'}
					mb={'16px'}
				>
					<FormDropdown
						id={'title'}
						label={'Tytuł (Pan/Pani)'}
						validated={validated}
						errorMsg={formValidation.titleError}
						value={formInfo.personalTitle}
						values={['Pan', 'Pani']}
						valueChangeHandler={titleHandler}
					/>
					<FormTextField
						id={'name'}
						type={'text'}
						label={'Imię'}
						validated={validated}
						errorMsg={formValidation.nameError}
						value={formInfo.firstName}
						valueChangeHandler={nameHandler}
					/>
					<Box
						sx={{
							'& span': {
								fontSize: typography.subtitle2.fontSize,
								fontWeight: '500',
								color: palette.primary.main,
							},
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										borderRadius: 3,
										width: 16,
										height: 16,
										mr: '6px',
									}}
								/>
							}
							label='Czy posiadasz drugie imię?'
							onChange={(e: any) => setIsSecondName(e.target.checked)}
							sx={{
								ml: '2px',
							}}
						/>
					</Box>
					{isSecondName && <FormTextField
						id={'secondName'}
						type={'text'}
						label={'Drugie imię'}
						validated={validated}
						errorMsg={formValidation.secondNameError}
						value={formInfo.secondName}
						valueChangeHandler={secondNameHandler}
					/>}
					<FormTextField
						id={'surname'}
						type={'text'}
						label={'Nazwisko'}
						validated={validated}
						errorMsg={formValidation.surnameError}
						value={formInfo.lastName}
						valueChangeHandler={surnameHandler}
					/>
					<Box
						display={'flex'}
						flexDirection={'column'}
						width={'100%'}
						gap={'16px'}
					>
						<FormTextField
							id={'street'}
							type={'text'}
							label={'Adres szkoły do korespondencji'}
							placeholder='Ulica'
							validated={validated}
							errorMsg={formValidation.streetError}
							value={formInfo.street}
							valueChangeHandler={streetHandler}
						/>
						<FormTextField
							id={'building'}
							type={'text'}
							label={''}
							placeholder='Numer budynku'
							validated={validated}
							errorMsg={formValidation.buildingError}
							value={formInfo.building}
							valueChangeHandler={buildingHandler}
						/>
						<FormTextField
							id={'apartment'}
							type={'text'}
							label={''}
							placeholder='Numer mieszkania'
							validated={validated}
							errorMsg={formValidation.apartmentError}
							value={formInfo.apartment}
							valueChangeHandler={apartmentHandler}
						/>
						<Box
							display={'flex'}
							gap={'16px'}
							justifyContent={'space-between'}
						>
							<FormTextField
								id={'zip'}
								type={'text'}
								label={''}
								placeholder='__-___'
								validated={validated}
								errorMsg={formValidation.zipCodeError}
								value={formInfo.zipCode}
								valueChangeHandler={zipCodeHandler}
							/>
							<FormTextField
								id={'city'}
								type={'text'}
								label={''}
								placeholder='Miasto'
								validated={validated}
								errorMsg={formValidation.cityError}
								value={formInfo.city}
								valueChangeHandler={cityHandler}
							/>
						</Box>
					</Box>
					<FormTextField
						id={'degree'}
						type={'text'}
						label={'Stopień naukowy'}
						validated={validated}
						errorMsg={formValidation.degreeError}
						value={formInfo.academicDegree}
						valueChangeHandler={degreeHandler}
					/>
					<FormDropdown
						id={'category'}
						label={'Kategoria'}
						validated={validated}
						errorMsg={formValidation.categoryError}
						value={formInfo.category}
						values={categoryNames}
						valueChangeHandler={categoryHandler}
					/>
					<FormTextField
						id={'affiliation'}
						type={'text'}
						label={'Afiliacja (należy podać miejsce pracy: nazwę szkoły lub uczelnie) '}
						multiline
						validated={validated}
						errorMsg={formValidation.affiliationError}
						value={formInfo.affiliation}
						valueChangeHandler={affiliationHandler}
					/>
					<FormTextField
						id={'students'}
						type={'text'}
						label={'Uczniowie (prosimy o wpisanie w jednym polu wszystkich podopiecznych zgodnie z formatem: Anna Kowalska, Jan Nowak, ...)'}
						multiline
						validated={validated}
						errorMsg={formValidation.studentsError}
						value={formInfo.students}
						valueChangeHandler={studentsHandler}
						labelSx={{
							whiteSpace: 'pre-wrap'
						}}
					/>
					<FormTextField
						id={'email'}
						type={'email'}
						label={'E-mail - do wysyłki dyplomów/listów gratulacyjnych'}
						validated={validated}
						errorMsg={formValidation.emailError}
						value={formInfo.email}
						valueChangeHandler={emailHandler}
					/>
					<FormTextField
						id={'phone'}
						type={'tel'}
						label={'Numer telefonu (opcjonalnie)'}
						validated={validated}
						errorMsg={formValidation.phoneError}
						value={formInfo.phone}
						valueChangeHandler={phoneHandler}
					/>
					<FormDropdown
						id={'district'}
						label={'Okręg'}
						validated={validated}
						errorMsg={formValidation.districtError}
						value={helperInputInfo.district}
						values={districtNames}
						valueChangeHandler={districtHandler}
					/>
					<TextWithDivider
						text={'Zgody'}
						dividerColor={palette.secondary.light}
						sx={{
							mt: '32px',
						}}
					/>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '8px',
							'& span': {
								fontSize: typography.subtitle2.fontSize,
								fontWeight: '500',
								color: palette.primary.main,
							},
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										borderRadius: 3,
										width: 16,
										height: 16,
										mr: '6px',
									}}
								/>
							}
							label='Zgoda RODO'
							onChange={rodoHandler}
							sx={{
								ml: '2px',
							}}
						/>
						<FormControlLabel
							control={
								<Checkbox
									sx={{
										borderRadius: 3,
										width: 16,
										height: 16,
										mr: '6px',
									}}
								/>
							}
							label='Oświadczam, że powyższe dane są zgodne ze stanem faktycznym'
							onChange={consentHandler}
							sx={{
								ml: '2px',
							}}
						/>
					</Box>
				</Box>
				<Box
					mt={'16px'}
					mb={'56px'}
					alignSelf={'center'}
					display={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
					position={'relative'}
				>
					<Box alignSelf={'center'}>
						<MainButton
							title={'Zarejestruj się'}
							darkVersion
							onClick={actionSend}
							sx={{
								alignSelf: 'center',
								lineHeight: '20px',
								letterSpacing: '0.18px',
							}}
						/>
					</Box>
					<CircularProgress
						sx={{
							display: loading ? 'block' : 'none',
							position: 'absolute',
							right: '-60px',
						}}
					/>
				</Box>
			</Grid>
			<DividerWithButton
				label={'Masz już konto?'}
				hrefPath={loginPage}
				buttonTitle={'Zaloguj się'}
			/>
		</Grid>
	);
};

export default RegisterTeacher;
