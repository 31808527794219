import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	useTheme,
} from '@mui/material';
import { getTeacherData, updateTeacherData } from '../../api/content-api';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import SnackbarContext from '../../store/snackbar-context';
import { useNavigate } from 'react-router-dom';
import { loginPage } from '../../store/fixed-routing';
import ManagedFormContext from '../../ui/Form/MangedForm';
import { TextFormField } from './NewSchoolDialog';
import ManagedFormSelectField from '../../ui/Form/ManagedFormSelectField';
import Teacher from '../../model/Teacher';

type Props = {
	teacherWpId: number | null;
	open: boolean;
	onClose: () => void;
};
const TeacherEditDialog = ({ open, teacherWpId, onClose }: Props) => {
	const { palette } = useTheme();
	const { token, logout, role } = useContext(AuthContext);
	const navigate = useNavigate();
	const { setMsg } = useContext(SnackbarContext);
	const { reset, validate, getFormData } = useContext(ManagedFormContext);
	const [sendingRequest, setSendingRequest] = useState(false);
	const [teacher, setTeacher] = useState<Teacher>();

	useEffect(() => {
		getTeacherData(token, teacherWpId)
			.then((response) => {
				if (response.status === 200) {
					const teacher = Teacher.fromApiResponse(response.data);
					setTeacher(teacher);
				}
			})
			.catch((exception) => {
				console.error(exception);
				setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
			});
	}, [teacherWpId]);

	const handleApprove = () => {
		const validationResult = validate();
		if (validationResult) {
			setMsg({ msg: validationResult, severity: 'warning' });
			return;
		}
		setSendingRequest(true);
		if (sendingRequest) {
			return;
		}
		const formData = getFormData();
		if(formData.students.length >= 1000) {
			setMsg({ msg: 'Przekroczono ilość znaków w polu uczniowie (maks. 1000 znaków)', severity: 'warning' });
			return;
		}
		const request = {
			id: teacher?.id,
			...formData,
		};
		updateTeacherData(token, request)
			.then((response) => {
				if (response.status === 200) {
					setMsg({ msg: 'Dane zostały zaktualizowane', severity: 'success' });
					setTimeout(() => window.location.reload(), 2000);
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setSendingRequest(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else if (exception?.response?.data?.message) {
					setMsg({ msg: exception?.response?.data?.message });
				}
				setSendingRequest(false);
			});
	};

	return (
		<>
			<Dialog
				open={open && !!teacher}
				onClose={() => {
					reset();
					onClose();
				}}
			>
				<DialogTitle sx={{ fontSize: '24px' }}>Dane konta</DialogTitle>
				<DialogContent>
					<Grid
						item
						container
						justifyContent={'space-between'}
					>
						<ManagedFormSelectField
							initialCorrect
							label={'Tytuł'}
							accessor={'personalTitle'}
							initialValue={teacher?.personalTitle}
							options={[
								{ label: 'Pan', value: 'Pan' },
								{ label: 'Pani', value: 'Pani' },
							]}
							formControlProps={{
								sx: {
									my: '10px',
									width: `calc(50% - 10px)`,
								},
							}}
							menuItemProps={{
								sx: { fontSize: '16px' },
							}}
						/>
						<TextFormField
							label={'Tytuł naukowy'}
							initialCorrect
							accessor={'academicDegree'}
							initialValue={teacher?.academicDegree}
						/>
						<TextFormField
							label={'Imię'}
							initialCorrect
							accessor={'firstName'}
							initialValue={teacher?.firstName}
						/>
						<TextFormField
							label={'Drugie imię'}
							initialCorrect
							accessor={'secondName'}
							initialValue={teacher?.secondName}
						/>
						<TextFormField
							label={'Nazwisko'}
							initialCorrect
							accessor={'lastName'}
							initialValue={teacher?.lastName}
						/>
						<TextFormField
							label={'Kategoria'}
							initialCorrect
							disabled={role === 'teacher'}
							accessor={'category'}
							initialValue={teacher?.category}
						/>
						<TextFormField
							type={'phone'}
							initialCorrect
							optional={true}
							label={'Telefon'}
							accessor={'phone'}
							initialValue={teacher?.phone ? teacher?.phone : ' '}
						/>
						<TextFormField
							type={'email'}
							initialCorrect
							disabled
							label={'Email'}
							accessor={'email'}
							initialValue={teacher?.email}
						/>
						<TextFormField
							initialCorrect
							multiline
							rows={3}
							max={255}
							fullWidth
							label={'Afiliacja'}
							accessor={'affiliation'}
							initialValue={teacher?.affiliation}
						/>
						<TextFormField
							initialCorrect
							multiline
							rows={3}
							fullWidth
							disabled={role === 'teacher'}
							label={'Uczniowie'}
							accessor={'students'}
							initialValue={teacher?.students}
						/>
					</Grid>
					<Typography>Adres</Typography>
					<Grid
						item
						container
						justifyContent={'space-between'}
					>
						<TextFormField
							initialCorrect
							label={'Ulica'}
							accessor={'street'}
							fullWidth
							initialValue={teacher?.street}
						/>
						<TextFormField
							initialCorrect
							label={'Numer budynku'}
							accessor={'building'}
							initialValue={teacher?.building}
						/>
						<TextFormField
							initialCorrect
							optional
							label={'Numer mieszkania'}
							accessor={'apartment'}
							initialValue={teacher?.apartment}
						/>
						<TextFormField
							initialCorrect
							label={'Kod pocztowy'}
							type={'zipCode'}
							accessor={'zipCode'}
							initialValue={teacher?.zipCode}
						/>
						<TextFormField
							initialCorrect
							label={'Miasto'}
							accessor={'city'}
							initialValue={teacher?.city}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						sx={{
							color: palette.secondary.main,
						}}
						onClick={() => {
							onClose();
							setTimeout(() => reset(), 500);
						}}
					>
						Anuluj
					</Button>
					<Button
						sx={{
							color: palette.success.main,
						}}
						onClick={() => handleApprove()}
					>
						{sendingRequest ? <CircularProgress /> : 'Zapisz'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default TeacherEditDialog;
