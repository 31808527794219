import { Box, Grid, Typography, useTheme } from '@mui/material';
import NewsTile from '../components/NewsTile';
import MainButton from '../components/UI/MainButton';
import BlockWrapper from '../components/BlockWrapper';
import { getLineHighlight } from '../styles/theme';

type Props = {
	content: any;
	highlightTitle?: boolean;
};

const NewsBlock = ({ content, highlightTitle = false }: Props) => {
	const { palette, breakpoints } = useTheme();
	const news = content?.news;

	return (
		<BlockWrapper
			justify={'center'}
			lgSize={12}
			xlSize={12}
			withPadding={false}
		>
			<Grid
				container
				flexDirection={'column'}
				alignItems={'center'}
				p={'80px 40px'}
				gap={'40px'}
				sx={{
					[breakpoints.down('md')]: {
						p: '40px 26px',
					},
				}}
			>
				{content?.title && (
					<Grid
						item
						container
						justifyContent={'flex-start'}
					>
						<Typography
							variant={'title'}
							color={palette.primary.main}
							sx={{
								position: 'relative',
								'&::after': highlightTitle
									? {
											display: 'inline-block',
											content: "''",
											left: '-10px',
											right: '0',
											top: '0',
											bottom: '0',
											position: 'absolute',
											backgroundImage: `url(${getLineHighlight().short})`,
											backgroundSize: '100% 80%',
											backgroundRepeat: 'no-repeat',
											backgroundPosition: 'center',
											width: 'calc(100% + 20px)',
											zIndex: -1,
									  }
									: {},
							}}
						>
							{content?.title}
						</Typography>
					</Grid>
				)}
				<Grid
					// display={'grid'}
					// justifyItems={{ xs: 'center', md: 'stretch' }}
					// alignItems={'center'}
					// width={'100%'}
					// gap={'20px'}
					// sx={{
					// 	gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
					// 	[breakpoints.down('md')]: {
					// 		justifyContent: 'center',
					// 		gap: '40px',
					// 		rowGap: '40px',
					// 	},
					// }}
					width={'100%'}
					container
					item
					gap={'20px'}
					justifyContent={'center'}
				>
					{news &&
						news.map((item: any, index: number) => {
							return (
								<Grid
									item
									key={index}
									sx={{
										flex: '0 0 250px',
									}}
								>
									<NewsTile
										key={index}
										item={item}
									/>
								</Grid>
							);
						})}
				</Grid>
				{content?.all_news_link && (
					<Box
						display={'flex'}
						flexDirection={'column'}
						alignItems={'flex-end'}
						gap={'10px'}
						width={'100%'}
					>
						<MainButton link={content.all_news_link} />
					</Box>
				)}
			</Grid>
		</BlockWrapper>
	);
};

export default NewsBlock;
