import React, { useContext, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ManagedFormContext from './MangedForm';
import { FormControlProps } from '@mui/material/FormControl/FormControl';
import { InputLabelProps } from '@mui/material/InputLabel/InputLabel';
import { SelectProps } from '@mui/material/Select/Select';
import { MenuItemProps } from '@mui/material/MenuItem/MenuItem';

export type ManagedOptions = {
	value: any;
	label: any;
};

export type ManagedFormSelectFieldProps = {
	formControlProps?: FormControlProps;
	inputLabelProps?: InputLabelProps;
	selectProps?: SelectProps;
	menuItemProps?: MenuItemProps;
	label: string;
	accessor: string;
	errorMessage?: string;
	optional?: boolean;
	initialValue?: any;
	initialCorrect?: boolean;
	options: ManagedOptions[];
};
const ManagedFormSelectField = ({
	formControlProps,
	inputLabelProps,
	selectProps,
	menuItemProps,
	label,
	accessor,
	errorMessage,
	optional,
	initialValue,
	initialCorrect,
	options,
}: ManagedFormSelectFieldProps) => {
	const { data, validated, setValue, setInitialProperties } = useContext(ManagedFormContext);

	const DEFAULT_ERROR = `Uzupełnij prawidłwo pole: ${label}`;
	const error = errorMessage ? errorMessage : DEFAULT_ERROR;

	useEffect(() => {
		setInitialProperties(accessor, initialValue, error, !!optional, !initialCorrect);
	}, []);

	const handleChangeValue = (event: SelectChangeEvent<any>) => {
		const value = event.target.value;
		setValue(accessor, value);
	};

	return (
		<FormControl {...formControlProps}>
			<InputLabel {...inputLabelProps}>{label}</InputLabel>
			{data[accessor] && (
				<Select
					{...selectProps}
					value={data[accessor].value}
					label={label}
					onChange={handleChangeValue}
					error={validated && data[accessor].error && !optional}
				>
					{options.map((o, index) => (
						<MenuItem
							{...menuItemProps}
							value={o.value}
							key={index}
						>
							{o.label}
						</MenuItem>
					))}
				</Select>
			)}
		</FormControl>
	);
};

export default ManagedFormSelectField;
