import axios from 'axios';
import {Dayjs} from "dayjs";

export const sendLoginRequest = async (login: string, password: string, onError?: (error: string) => void) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/jwt-auth/v1/token' : '';

	const formData = new FormData();
	formData.append('username', login);
	formData.append('password', password);

	const config = {
		headers: {},
	};
	try {
		const response = await axios.post(url, formData, config);
		if (response.status != 200) {
			onError && onError('Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.');
		}
		return response.data.data;
	} catch (error: any) {
		console.error(error.response ? error.response : error);
		if (error.response.status === 403) {
			onError && onError('Nieprawidłowy login lub hasło');
		} else {
			onError && onError('Nastąpił błąd przy wysyłaniu formularza! Spróbuj ponownie później.');
		}
	}
	return null;
};

export const sendResetLinkRequest = async (email: string, onError?: (error: string) => void) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/registration/reset-link' : '';

	const formData = new FormData();
	formData.append('email', email);

	const config = {
		headers: {},
	};
	try {
		const response = await axios.post(url, formData, config);
		if (response.status != 200) {
			console.error(response);
		}
		return response.data;
	} catch (error: any) {
		console.error(error.response ? error.response : error);
	}
	return null;
};

export const sendResetPassword = async (
	login: string,
	key: string,
	password: string,
	onError?: (error: string) => void,
) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/registration/password-reset' : '';

	const config = {
		headers: {},
	};
	try {
		const response = await axios.post(url, { login, key, password }, config);
		if (response.status != 200) {
			console.error(response);
		}
		return response.data;
	} catch (error: any) {
		console.error(error.response ? error.response : error);
	}
	return null;
};

export const registerNewTeacher = async (
	formInfo: {
		personalTitle: string;
		academicDegree: string;
		firstName: string;
		secondName: string;
		lastName: string;
		category: string;
		affiliation: string;
		students: string;
		email: string;
		phone: string;
		city: string;
		street: string;
		zipCode: string;
		building: string;
		apartment: string;
		districtId: string;
	},
	onError?: (error: string) => void,
) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/registration/teacher' : '';

	const data = JSON.stringify(formInfo);

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};
	try {
		const response = await axios.post(url, data, config);
		if (response.status != 200) {
			console.error(response);
		}
		return response.data;
	} catch (error: any) {
		console.error(error.response ? error.response : error);
		return error.response ? error.response.data.message : error;
	}
};

export const registerNewStudent = async (
	formInfo: {
		teachersIds: any;
		specializationId: string;
		personalTitle: string;
		parentName: string;
		parentEmail: string;
		firstName: string;
		lastName: string;
		email: string;
		phone: string;
		city: string;
		street: string;
		zipCode: string;
		building: string;
		apartment: string;
		districtId: string;
		schoolId: string;
		birthday: Dayjs | null;
		specialCertificate: boolean;
	},
	onError?: (error: string) => void,
) => {
	const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/registration/student' : '';

	const data = JSON.stringify(formInfo);

	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};
	try {
		const response = await axios.post(url, data, config);
		if (response.status != 200) {
			console.error(response);
		}
		return response.data;
	} catch (error: any) {
		console.error(error.response ? error.response : error);
		return error.response ? error.response.data.message : error;
	}
	return null;
};
