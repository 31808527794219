import District from './District';

class Teacher {
	id: number;
	wpUserId: number;
	personalTitle: string;
	academicDegree: string;
	firstName: string;
	secondName: string;
	lastName: string;
	category: string;
	affiliation: string;
	students: string;
	email: string;
	phone: string;
	approved: boolean;
	district?: District;
	city: string;
	zipCode: string;
	street: string;
	building: string;
	apartment: string;

	constructor(
		id: number,
		wpUserId: number,
		personalTitle: string,
		academicDegree: string,
		firstName: string,
		secondName: string,
		lastName: string,
		category: string,
		affiliation: string,
		students: string,
		email: string,
		phone: string,
		district: District | undefined,
		approved: boolean,
		city: string,
		zipCode: string,
		street: string,
		building: string,
		apartment: string,
	) {
		this.id = id;
		this.wpUserId = wpUserId;
		this.personalTitle = personalTitle;
		this.academicDegree = academicDegree;
		this.firstName = firstName;
		this.secondName = secondName;
		this.lastName = lastName;
		this.category = category
		this.affiliation = affiliation;
		this.students = students;
		this.email = email;
		this.phone = phone;
		this.district = district;
		this.approved = approved;
		this.city = city;
		this.zipCode = zipCode;
		this.street = street;
		this.building = building;
		this.apartment = apartment;
	}

	public static fromApiResponse(data: any) {
		return new Teacher(
			data.id ? data.id : 0,
			data.wp_user_id ? data.wp_user_id : 0,
			data.personal_title ? data.personal_title : '',
			data.academic_degree ? data.academic_degree : '',
			data.first_name ? data.first_name : '',
			data.second_name ? data.second_name : '',
			data.last_name ? data.last_name : '',
			data.category ? data.category : '',
			data.affiliation ? data.affiliation : '',
			data.students ? data.students : '',
			data.email ? data.email : '',
			data.phone ? data.phone : '',
			data.district ? District.fromApiResponse(data.district) : undefined,
			data.approved === 1,
			data.city ? data.city : '',
			data.zip_code ? data.zip_code : '',
			data.street ? data.street : '',
			data.building ? data.building : '',
			data.apartment ? data.apartment : '',
		);
	}
}

export default Teacher;
